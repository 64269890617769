



































  import { ServerResponse } from '@/services/response.types';
  import { TransactionHistoryModel, TransactionModel } from '@/store/transaction/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Action, State } from 'vuex-class';
  import filter from '@/constant/Filters';
import { UserModel } from '@/store/auth/types';

  @Component({
    name: 'TransactionHistory',
    components: {},
    filters: { ...filter },
  })
  export default class TransactionHistory extends Vue {
    @Action('GetTransactionHistory', { namespace: 'transaction' }) GetTransactionHistory!: (params?: any) => Promise<ServerResponse>;

    @State('history', { namespace: 'transaction' }) history!: TransactionHistoryModel;
    @State('OnLoad', { namespace: 'transaction' }) show!: boolean;
    @State('user', { namespace: 'auth' }) user!: UserModel;

    public get ListData(): TransactionModel[] {
      return this.history.listdata.filter((item) => item.trx_id);
    }

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      this.GetTransaction();
    }

    public lastScroll = 0
    public currentPage = 1
    public lastPage = false
    /**
     * GetTransaction
    */
    public GetTransaction(params: any = {}) {

      // params.customer_email = this.user.email

      this.GetTransactionHistory(params)
        .then((response) => {
          window.addEventListener('scroll', () => {
              let element = document.querySelector('#TransactionHistory');
              var offset = element.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
              const top = window.pageYOffset + window.innerHeight - offset;

              let cardHeightAvg = 400
              let isScrollDown = top > this.lastScroll

              if (top >= element.scrollHeight - cardHeightAvg && this.show === false && isScrollDown && !this.lastPage && this.currentPage < this.history.total) {
                this.currentPage = Math.ceil(this.ListData.length/10)
                this.GetTransactionHistory({page: this.currentPage}).finally(()=>this.lastScroll = top).finally(()=>this.lastPage = this.currentPage === Math.ceil(this.ListData.length/10))
              }
            },
            { passive: false }
          );
        }).catch(() => this.GetTransaction());
    }
  }
